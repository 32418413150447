import React from 'react'
import Invoice from '../component/Invoice'

function Checkout() {
  return (
    <div>
        <Invoice />
    </div>
  )
}

export default Checkout